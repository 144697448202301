import { ValueDocLink } from 'types/dataModels';
import { v4 as uuidv4 } from 'uuid';

/**
 * This will take html and turn it into the value that we will hold in the backend
 * @param { string } content
 * @returns { sumbitData }
 */
export const transformToTemplate = (content: string) => {
    // We will get the text surrounded by a tags, find that text and then replace it with the ID
    const updatedArr: ValueDocLink[] = [];
    const emptyLabelArr: ValueDocLink[] = [];
    const parser = new DOMParser();

    const contentHTML = parser.parseFromString(content, 'text/html');
    const anchorAr = contentHTML.getElementsByTagName('a');
    // Replace text and update the phrase link array

    let anc;
    let ancHref: string;
    let ancText;
    let ancId;
    let fileId;
    let isHidden: boolean;
    let editable: string;
    let newLink: ValueDocLink;
    while (anchorAr.length > 0) {
        anc = anchorAr[0];
        ancHref = anc.getAttribute('href');
        editable = anc.getAttribute('editable');
        ancText = anc.innerText;
        ancId = anc.id || uuidv4();
        isHidden = anc.getAttribute('data-is-hidden') === 'true';
        fileId = anc.getAttribute('data-file-id');
        fileId = typeof fileId === 'string' && fileId == 'null' ? null : fileId;
        newLink = {
            id: ancId,
            docId: ancHref,
            fileId,
            label: ancText,
            isHidden,
            isEmbeddedImage: !(editable === 'true')
        };
        if (!ancText) emptyLabelArr.push(newLink);
        else updatedArr.push(newLink);
        anc.replaceWith('{' + ancId + '}');
    }

    let retValue = contentHTML.body.innerHTML;
    retValue = retValue.replaceAll(/<br\s*\/?>/gi, '\n');
    retValue = retValue.replaceAll(/&nbsp; &nbsp; /g, '\t');

    const temp = parser.parseFromString(retValue, 'text/html');
    const paragraphTags = temp.querySelectorAll('p');
    if (paragraphTags.length === 1) {
        retValue = paragraphTags[0].innerHTML;
    }

    if (emptyLabelArr.length) {
        emptyLabelArr.forEach((link) => {
            retValue = retValue.replace(`{${link.id}}`, '');
        });
    }
    return {
        submitString: retValue,
        updatedLinkArray: updatedArr
    };
};

export const transformToHTML = (
    content: string,
    valueDocLinks: ValueDocLink[],
    isFinalReview?: Boolean
): string => {
    let htmlContent = content;
    // There is a bug here with going to final review with empty legal/vesting
    if (valueDocLinks) {
        valueDocLinks.forEach((dl) => {
            const docId = dl.docId ? dl.docId : dl.multiDocIds.join(',');
            const labelToFind = `{${dl.id}}`;
            const onClickFuntion = "onClick='handleDocLinkClick(event)'";
            const label = `<a href="${docId}" class=${dl.isHidden ? 'editor-hidden-link' : '-'} data-file-id="${dl.fileId}"data-mce-href="${docId}" title="Ctrl + Click to follow link" doclink="true" data-is-hidden=${dl.isHidden} ${isFinalReview ? onClickFuntion : ''} data-is-hidden=${dl.isHidden}>${dl.label}</a>`;
            htmlContent = htmlContent.replace(labelToFind, label);
        });
    }
    if (htmlContent) {
        htmlContent = htmlContent.replaceAll(/\r\n|\r|\n/g, '<br>');
        htmlContent = htmlContent.replaceAll(/\t/g, '&nbsp; &nbsp; ');
    }
    return `<p>${htmlContent}</p>`;
};

/**
 * #########################
 * Functions that deal with manipulating the text within the editor
 *
 * #########################
 */

/**
 * This handles the display of data. This function reads from the array of phrase
 * doc links to populate the text
 * @param { string } content The string that comes from backend
 * @param { ValueDocLink[] } valueDocLinks ValueDocLinks
 * @returns { result }The content to be displayed in the TinyMCE editor
 */
export const convertTextForEditor = (content: string, valueDocLinks: ValueDocLink[]) => {
    let htmlContent = '';

    htmlContent = content;
    if (valueDocLinks) {
        valueDocLinks.forEach((dl) => {
            const docId = dl.docId ? dl.docId : dl.multiDocIds.join(',');
            const labelToFind = `{${dl.id}}`;
            const anchor = document.createElement('a');
            anchor.href = docId;
            dl.fileId && anchor.setAttribute('data-file-id', dl.fileId);
            anchor.setAttribute('editable', `${!dl.isEmbeddedImage}`);
            anchor.className = dl.isHidden ? 'editor-hidden-link' : '';
            anchor.title = 'Ctrl + Click to follow link';
            anchor.setAttribute('doclink', 'true');
            anchor.setAttribute('data-mce-href', docId);
            anchor.setAttribute('data-is-hidden', `${dl.isHidden}`);
            anchor.textContent = dl.label;

            htmlContent = htmlContent?.replace(labelToFind, anchor.outerHTML);
        });
    }

    // Clean the text
    // We no longer support <p> tags in the text other than the parent wrapper tags
    // All new lines should either be \n or <br>
    // 1. Replace all new lines with br tags
    htmlContent = htmlContent?.replaceAll(/\r\n|\r|\n/g, '<br>');

    // 2. Replace tabs with spaces
    htmlContent = htmlContent?.replaceAll(/\t/g, '&nbsp; &nbsp; ');

    return htmlContent;
};
