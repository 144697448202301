import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { SearchReport } from 'types/searchReport';
import { GetReqConfig } from 'types/dataModels';
import { WorkbenchSections } from 'core/constants/common';

/**
 * Get search report for current exam order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {GetReqConfig} config
 * @returns {SearchReport}
 */
export const getExamOrderSearchReport = async (
    orderId: string,
    config: GetReqConfig
): Promise<SearchReport> => {
    try {
        const response = await api.get<SearchReport>(
            `/api/examOrder/${orderId}/searchReport`,
            {
                data: {
                    section: WorkbenchSections.searchReport,
                    ...config
                }
            }
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};
