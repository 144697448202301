import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IkeyDocumentsGroupProps {
    /**
     * show only tagged documents
     */
    isTaggedOnly: boolean;
    /**
     * show only documents with selected category
     */
    currentCategory: string | string[];
}

export interface ISetKeyDocumentGroupPayloadAction {
    [key: string]: IkeyDocumentsGroupProps;
}

export interface IKeyDocumentsGroupingSlice {
    documentGroupsData : {[key: string]: IkeyDocumentsGroupProps;}
}

const initialState: IKeyDocumentsGroupingSlice = { documentGroupsData : {} };

const keyDocumentsGroupingSlice = createSlice({
    name: 'keyDocumentsGrouping',
    initialState,
    reducers: {
        /**
         * Set UI controls data for a particular key document group
         * @param state Slice state
         * @param action Payload with the key document group data to set
         */
        setKeyDocumentsGroup(
            state: IKeyDocumentsGroupingSlice,
            action: PayloadAction<ISetKeyDocumentGroupPayloadAction>
        ) {
            state.documentGroupsData = action.payload;
        }
    }
});

export const { setKeyDocumentsGroup } = keyDocumentsGroupingSlice.actions;
export default keyDocumentsGroupingSlice.reducer;
