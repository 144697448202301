import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { ValueDocLink, VestingValue } from 'types/dataModels';
import { VestingResultDto } from 'types/dto/vesting';
import { WorkbenchSections } from 'core/constants/common';
import { GetReqConfig } from 'types/dataModels';

/**
 * Get order vesting by orderId
 * @function
 * @category API
 * @param {string} orderId - Current order id value
 * @param {GetReqConfig} config
 * @returns {VestingValue[]}
 */
export const getExamOrderVesting = async (
    orderId: string,
    config: GetReqConfig
): Promise<VestingValue[]> => {
    try {
        const response = await api.get<VestingValue[]>(`/api/examOrder/${orderId}/vesting`, {
            data: {
                section: WorkbenchSections.vesting,
                ...config
            }
        });
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Submit editor value as vesting value for current order
 * @function
 * @category API
 * @param {string} orderId - Current order id value
 * @param {string} examValue - Vesting value
 * @param {boolean} isTagged - save value
 * @param {ValueDocLink} examValueDocLinks - array of document links within the vesting editor
 */
export const saveFinalOrderVesting = async (
    orderId: string,
    examValue: string,
    isTagged: boolean,
    examValueDocLinks: ValueDocLink[]
) => {
    try {
        const response = await api.put<
            {
                examValue: string;
                isTagged: boolean;
                examValueDocLinks: ValueDocLink[];
            },
            VestingResultDto
        >(`/api/examOrder/${orderId}/vesting/exam`, {
            examValue: examValue,
            isTagged: isTagged,
            examValueDocLinks: examValueDocLinks
        });
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Submit current vesting instrument as vesting value for order
 * @function
 * @category API
 * @param {string} orderId - Current order id value
 * @param {string} vestingId - Target vesting instrument id value
 * @param {string} instrumentString - Instrument string value,
 * @param {ValueDocLink[]} linkArray - attached document array,
 * @returns {VestingResultDto}
 */
export const applyInstrumentAsOrderVesting = async (
    orderId: string,
    vestingId: string,
    instrumentString: string,
    linkArray?: ValueDocLink[]
) => {
    try {
        const response = await api.put<
            {
                examValue: string;
                examValueDocLinks: ValueDocLink[];
            },
            VestingResultDto
        >(`/api/examOrder/${orderId}/vesting/${vestingId}/apply`, {
            examValue: instrumentString,
            examValueDocLinks: linkArray
        });
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
