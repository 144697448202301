import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AssignedTo,
    DEFAULT_GUID,
    OrdersListTableHeaders,
    OrdersListTableSorting,
    OrderStatusesIds
} from 'core/constants/common';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import {
    ExaminerOrder,
    ExaminerOrderList,
    FieldActionStatus,
    ModalActions,
    MultipleProjectOrderUpdateResponse,
    PagedData,
    ProjectOrder,
    SelectedOrder
} from 'types/dataModels';
import { SelectItem } from 'types/propTypes';

export interface ManagerOrdersData {
    orders: {
        isFiltersShown: boolean;
        allOrders: PagedData<ProjectOrder>;
    };
    currentFilters: {
        currentStateInFilter: string[];
        currentCountyInFilter: string[];
        currentProductTypeInFilter: string[];
        currentBusinessSegmentInFilter: string[];
        currentActionInFilter: string[];
        currentProjectStatusInFilter: string[];
        currentOrderFilter: string;
        currentAssignedToFilter: AssignedTo;
        currentRushOrderFilter: boolean;
        currentClientInFilter: SelectItem;
        search: string;
        sorting: {
            fieldSorting: { name: OrdersListTableSorting; direction: 0 | 1 }[];
        };
        fromDate?: string;
        toDate?: string;
    };
    currentSortingColumn: OrdersListTableHeaders;
    modalActions: {
        onHoldOrder?: FieldActionStatus;
        reassignOrder?: FieldActionStatus;
    };
    selectedOrders: SelectedOrder[];
    examinerOrderList: ExaminerOrderList[];
}

const initialState: ManagerOrdersData = {
    orders: {
        isFiltersShown: false,
        allOrders: {
            data: [],
            page: 1,
            pageSize: 20,
            count: 0,
            totalPages: 0,
            totalCount: 0,
            pageLimit: 100
        }
    },
    currentFilters: {
        currentStateInFilter: [],
        currentCountyInFilter: [],
        currentProductTypeInFilter: [],
        currentBusinessSegmentInFilter: [],
        currentActionInFilter: [],
        currentProjectStatusInFilter: [],
        currentOrderFilter: '',
        currentAssignedToFilter: null,
        currentRushOrderFilter: false,
        currentClientInFilter: null,
        search: '',
        sorting: {
            fieldSorting: [{ name: OrdersListTableSorting.orderNumber, direction: 0 }]
        },
        fromDate: '',
        toDate: ''
    },
    currentSortingColumn: OrdersListTableHeaders.sla,
    selectedOrders: [],
    modalActions: {
        onHoldOrder: null,
        reassignOrder: null
    },
    examinerOrderList: []
};

const managerOrdersSlice = createSlice({
    name: 'managerOrders',
    initialState,
    reducers: {
        setOrders(state, action: PayloadAction<PagedData<ProjectOrder>>) {
            state.orders.allOrders = action.payload;
        },
        setIsFiltersShown(state, action: PayloadAction<boolean>) {
            state.orders.isFiltersShown = action.payload;
        },
        /**
         * Set current states to filter orders by
         * @param state Slice state
         * @param action Payload with the array of state IDs to set in the filter
         */
        setCurrentStateInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentStateInFilter = action.payload;
        },
        /**
         * Set current counties to filter orders by
         * @param state Slice state
         * @param action Payload with the array of county IDs to set in the filter
         */
        setCurrentCountyInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentCountyInFilter = action.payload;
        },
        /**
         * Set current product types to filter orders by
         * @param state Slice state
         * @param action Payload with the array of product type IDs to set in the filter
         */
        setCurrentProductTypeInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentProductTypeInFilter = action.payload;
        },
        /**
         * Set current business segments to filter orders by
         * @param state Slice state
         * @param action Payload with the array of business segments IDs to set in the filter
         */
        setCurrentBusinessSegmentInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentBusinessSegmentInFilter = action.payload;
        },
        /**
         * Set current action to filter orders by
         * @param state Slice state
         * @param action Payload with the array of action IDs to set in the filter
         */
        setCurrentActionInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentActionInFilter = action.payload;
        },
        /**
         * Set current project statuses to filter orders by
         * @param state Slice state
         * @param action Payload with the array of project status IDs to set in the filter
         */
        setCurrentProjectStatusInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentProjectStatusInFilter = action.payload;
        },
        /**
         * Set current order filter to filter orders by
         * @param state Slice state
         * @param action Payload with the order filter string to set in the filter
         */
        setCurrentOrderFilter(state, action: PayloadAction<string>) {
            state.currentFilters.currentOrderFilter = action.payload;
        },
        /**
         * Set current assigned to order filter to filter orders by
         * @param state Slice state
         * @param action Payload with the assigned to filter - true/false
         */
        setAssignedToFilter(state, action: PayloadAction<AssignedTo>) {
            state.currentFilters.currentAssignedToFilter = action.payload;
        },
        /**
         * Set current Rush filter to filter orders by
         * @param state Slice state
         * @param action Payload with isRushOrder boolean to set in the filter
         */
        setCurrentRushOrderFilter(state, action: PayloadAction<boolean>) {
            state.currentFilters.currentRushOrderFilter = action.payload;
        },
        /**
         * Set current clients to filter orders by
         * @param state Slice state
         * @param action Payload with the array of client IDs to set in the filter
         */
        setCurrentClientInFilter(state, action: PayloadAction<SelectItem>) {
            state.currentFilters.currentClientInFilter = action.payload;
        },
        /**
         * Set from and to date to filter order by date range
         * @param state Slice state
         * @param action Payload with the array of fromDate, toDate to set in the filter
         */
        setDateRangeInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.fromDate = action.payload[0];
            state.currentFilters.toDate = action.payload[1];
        },
        /**
         * Clear the filter data by setting it to its initial state
         * @param state Slice state
         */
        clearFilters(state) {
            state.currentFilters = initialState.currentFilters;
        },
        /**
         * Set current search keywords to filter orders by
         * @param state Slice state
         * @param action Payload with the keyword input to filter orders by
         */
        setSearch(state, action: PayloadAction<string>) {
            state.currentFilters.search = action.payload;
        },
        /**
         * Set sorting criteria for orders
         * @param state Slice state
         * @param action Payload with the sorting data to set
         */
        setSorting(state, action: PayloadAction<OrdersListTableSorting>) {
            //change current sorting parameter sorting direction
            if (state.currentFilters.sorting.fieldSorting[0]?.name === action.payload) {
                state.currentFilters.sorting.fieldSorting[0] = {
                    name: action.payload,
                    direction:
                        state.currentFilters.sorting.fieldSorting[0].direction === 0 ? 1 : 0
                };
            } else
                state.currentFilters.sorting.fieldSorting[0] = {
                    name: action.payload,
                    direction: 0
                };
        },
        /**
         * Set the column to sort orders by
         * @param state Slice state
         * @param action Payload with the enum value of the column to sort orders by
         */
        setCurrentSortingColumn(state, action: PayloadAction<OrdersListTableHeaders>) {
            state.currentSortingColumn = action.payload;
        },
        /**
         * Updates the order by
         * @param state Slice state
         * @param action Payload with updated order data
         */
        updateOrderFromOrdersData(state, action: PayloadAction<ProjectOrder>) {
            state.orders.allOrders.data = state.orders.allOrders.data.map((order) =>
                order.id === action.payload.id ? action.payload : order
            );
        },
        /**
         * Updates Existing orders data with new/updated orders data
         * @param state Slice state
         * @param action Payload with the response from api
         */
        updateListOfOrdersInOrdersData(
            state,
            action: PayloadAction<MultipleProjectOrderUpdateResponse[]>
        ) {
            state.orders.allOrders.data = state.orders.allOrders.data.map((order) => {
                const updatedOrder = action.payload.find(
                    (updatedOrder) => updatedOrder.projectId === order.id
                );
                return updatedOrder?.result ? updatedOrder.result : order;
            });
        },
        /**
         * sets current selected order data
         * @param state Slice state
         * @param action Payload with the selected order
         */
        setSelectedOrders(state, action: PayloadAction<SelectedOrder[]>) {
            state.selectedOrders = action?.payload?.length ? action?.payload : [];
        },
        /**
         * sets data for reassigned and on hold modal popup
         * @param state Slice state
         * @param action Payload with modal data
         */
        setModalActions(state, action: PayloadAction<ModalActions>) {
            state.modalActions = action.payload ?? {
                reassignOrder: null,
                onHoldOrder: null
            };
        },
        /**
         * sets all examiner orders data
         * @param state Slice state
         * @param action Payload with response from API for examiner orders data
         */
        setExaminerOrderList(state, action: PayloadAction<ExaminerOrderList[]>) {
            state.examinerOrderList = action.payload;
        },
        /**
         * Updates the order project status
         * @param state Slice state
         * @param action Payload with updated order data
         */
        updateOrderProjectStatus(state, action: PayloadAction<ProjectOrder>) {
            const order = state.orders.allOrders.data.find(
                (order) => order.id === action.payload.projectId
            );
            order.projectStatus = action.payload.projectStatus;
            order.projectStatusId = action.payload.projectStatusId;
        }
    }
});

export const {
    setOrders,
    setIsFiltersShown,
    setCurrentStateInFilter,
    setCurrentCountyInFilter,
    setCurrentClientInFilter,
    setDateRangeInFilter,
    setCurrentProductTypeInFilter,
    setCurrentProjectStatusInFilter,
    setCurrentOrderFilter,
    setAssignedToFilter,
    setCurrentRushOrderFilter,
    setCurrentBusinessSegmentInFilter,
    setCurrentActionInFilter,
    clearFilters,
    setSearch,
    setSorting,
    setCurrentSortingColumn,
    updateOrderFromOrdersData,
    setSelectedOrders,
    setModalActions,
    setExaminerOrderList,
    updateListOfOrdersInOrdersData,
    updateOrderProjectStatus
} = managerOrdersSlice.actions;

/**
 * Fetch paged list of orders from BE
 * @param {number} pageNumber Number of the page to fetch orders from
 * @param {number} pageSize Number of the page to fetch orders from
 * @param {number[]} stateIds IDs of the states to filter orders by
 * @param {number[]} countyIds IDs of the counties to filter orders by
 * @param {number[]} businessSegmentIds IDs of the business segments to filter orders by
 * @param {number[]} actionsIds IDs of the actions to filter orders by
 * @param {number[]} productTypeIds IDs of the product types to filter orders by
 * @param {string[]} clientIds IDs of the clients to filter orders by
 * @param {string[]} statusIds IDs of the statuses to filter orders by
 * @param {string} orderFilter Order filter
 * @param {string} search String that represents the user search keywords to filter orders by
 * @param {object} sorting Object that describes the sorting of the order list
 * @param {string} fromDate ISO date string to filter from date
 * @param {string} toDate ISO date string to filter to date
 * @param {boolean} isRush is rush order filter appiled or not
 * @param {AssignedTo} assignedTo specifies whether filter is Assigned | Unassigned | All orders
 * @returns {AppThunk}
 */
export const fetchPagedOrders =
    (
        pageNumber: number,
        pageSize: number,
        stateIds?: number[],
        countyIds?: number[],
        businessSegmentIds?: number[],
        actionsIds?: number[],
        productTypeIds?: number[],
        clientIds?: string[],
        statusIds?: string[],
        orderFilter?: string,
        search?: string,
        sorting?: {
            fieldSorting: { name: string; direction: 0 | 1 }[];
        },
        fromDate?: string,
        toDate?: string,
        isRush?: boolean,
        assignedTo?: AssignedTo
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.orders.getPagedOrdersDataApi({
                page: pageNumber,
                pageSize,
                stateIds,
                countyIds,
                businessSegmentIds,
                actionsIds,
                productTypeIds,
                clientIds,
                statusIds,
                orderFilter,
                search,
                sorting,
                fromDate,
                toDate,
                isRush,
                assigned: assignedTo
            });
            dispatch(setOrders(response));
        } catch (err) {
            dispatch(setOrders(initialState.orders.allOrders));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get project orders: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Start the exam of an existing order
 * @param {string} orderId ID of the order to start exam
 * @returns {AppThunk}
 */
export const startExamThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.orders.startExam(orderId);
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Start exam order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Reopen the exam of an existing order
 * @param {string} orderId ID of the order to start exam
 * @returns {AppThunk}
 */
export const reopenOrderThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.orders.reopenOrder(orderId);
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Reopen order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };   
/**
 * Unassign the order
 * @param {string} orderId ID of the order to unassign
 * @returns {AppThunk}
 */
export const unassignOrderThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.orders.unassignOrder(orderId);
            dispatch(updateOrderFromOrdersData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Unassign Order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * updates the rush order status
 * @param {string} orderId ID of the order to update rush order status
 * @param {boolean} isRush true/false
 * @returns {AppThunk}
 */
export const updateRushOrderStatusThunk =
    (orderId: string, isRush: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.orders.updateRushOrderStatus(orderId, isRush);
            dispatch(updateOrderFromOrdersData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update rush order status: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Cancel the order
 * @param {string} orderId ID of the order to cancel
 * @returns {AppThunk}
 */
export const cancelOrderThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.orders.cancelOrder(orderId);
            dispatch(updateOrderFromOrdersData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Cancel Order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the status of an existing order
 * @param {string} notes Pause reason
 * @returns {AppThunk}
 */
export const updateManagerExamOrderStatus =
    (notes?: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const projectId =
                getState().managerOrdersData?.modalActions?.onHoldOrder?.projectId ?? '';
            const response = await api.orders.updateManagerOrderStatus(
                projectId,
                OrderStatusesIds.OnHold,
                notes
            );
            dispatch(updateOrderFromOrdersData(response));
            dispatch(setModalActions({ onHoldOrder: null }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update exam order status: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Reassign the order
 * @param {string} userId user id
 * @returns {AppThunk}
 */
export const reassignOrderThunk =
    (userId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const projectId =
                getState().managerOrdersData?.modalActions?.reassignOrder?.projectId ?? '';
            const response = await api.orders.reassignOrder(projectId, userId);
            dispatch(updateOrderFromOrdersData(response));
            dispatch(setModalActions({ reassignOrder: null }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Reassign Order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Reassign the orders
 * @param {string} assignedUserId user id
 * @returns {AppThunk}
 */
export const markMultipleOrdersAsReassignedThunk =
    (assignedUserId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            // get order id list from selected orders
            const selectedOrders = getState().managerOrdersData.selectedOrders;
            const ordersList = selectedOrders.map((order) => {
                return {
                    projectId: order.projectId,
                    assignedUserId
                };
            });

            // mark rush API call
            const response = await api.orders.markMultipleOrdersAsReassgined(ordersList);

            // update list of success orders in orders data state
            dispatch(updateListOfOrdersInOrdersData(response));

            // reset selection
            dispatch(setSelectedOrders([]));
            dispatch(setModalActions({ reassignOrder: null }));

            // for failed orders, show error message
            const ordersData = getState().managerOrdersData.orders.allOrders.data;
            const errors = setMultiUpdateErrors(response, ordersData);
            if (errors.length) {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Reassign Order: \n ${errors.join('\n')}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Reassign Order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Marks the list of orders as Canceled
 * @returns {AppThunk}
 */
export const markMultipleOrdersAsCanceledThunk =
    (): AppThunk => async (dispatch, getState) => {
        try {
            // get order id list from selected orders
            const selectedOrders = getState().managerOrdersData.selectedOrders;
            const orderIdList = selectedOrders
                .filter((order) => order.orderStatus !== OrderStatusesIds.RemittingFailed)
                .map((order) => order.projectId);

            // cancel API call
            const response = await api.orders.markMultipleOrdersAsCanceled(orderIdList);

            // update list of success orders in orders data state
            dispatch(updateListOfOrdersInOrdersData(response));

            // reset selection
            dispatch(setSelectedOrders([]));

            // for failed orders, show error message
            const ordersData = getState().managerOrdersData.orders.allOrders.data;
            const errors = setMultiUpdateErrors(response, ordersData);
            if (errors.length) {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Cancel Order: \n ${errors.join('\n')}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Cancel Order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Marks the list of orders as Rush
 * @returns {AppThunk}
 */
export const markMultipleOrdersAsRushThunk = (): AppThunk => async (dispatch, getState) => {
    try {
        // get order id list from selected orders
        const selectedOrders = getState().managerOrdersData.selectedOrders;
        const ordersList = selectedOrders
            .filter((order) => order.orderStatus !== OrderStatusesIds.RemittingFailed)
            .map((order) => {
                return {
                    projectId: order.projectId,
                    isRush: true
                };
            });

        // mark rush API call
        const response = await api.orders.markMultipleOrdersAsRush(ordersList);

        // update list of success orders in orders data state
        dispatch(updateListOfOrdersInOrdersData(response));

        // reset selection
        dispatch(setSelectedOrders([]));

        // for failed orders, show error message
        const ordersData = getState().managerOrdersData.orders.allOrders.data;
        const errors = setMultiUpdateErrors(response, ordersData);
        if (errors.length) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Mark Rush Order: \n ${errors.join('\n')}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    } catch (err) {
        dispatch(
            setSnackbarState({
                open: true,
                message: `Mark Rush Order: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

/**
 * Marks the list of orders as Unassgined
 * @returns {AppThunk}
 */
export const markMultipleOrdersAsUnassginedThunk =
    (): AppThunk => async (dispatch, getState) => {
        try {
            // get order id list from selected orders
            const selectedOrders = getState().managerOrdersData.selectedOrders;
            // filter remit failed orders from unassignOrderList
            const orderIdList = selectedOrders
                .filter(
                    (order) =>
                        order.assignedTo !== DEFAULT_GUID &&
                        order.orderStatus !== OrderStatusesIds.RemittingFailed
                )
                .map((order) => order.projectId);

            // unassign API call
            const response = await api.orders.markMultipleOrdersAsUnassgined(orderIdList);

            // update list of success orders in orders data state
            dispatch(updateListOfOrdersInOrdersData(response));

            // reset selection
            dispatch(setSelectedOrders([]));

            // for failed orders, show error message
            const ordersData = getState().managerOrdersData.orders.allOrders.data;
            const errors = setMultiUpdateErrors(response, ordersData);
            if (errors.length) {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Unassign Order: \n ${errors.join('\n')}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Unassign Order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Get all Examiner members data
 * @returns {AppThunk}
 */
export const getAllExaminersNOrdersThunk = (): AppThunk => async (dispatch) => {
    try {
        const response = await api.orders.getAllExaminersNOrders();
        const examinerOrderList = getExaminerOrderList(response);
        dispatch(setExaminerOrderList(examinerOrderList));
    } catch (err) {
        dispatch(
            setSnackbarState({
                open: true,
                message: `Examiner Orders: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

/**
 * Get Examiner Order List data
 * @param {ExaminerOrder[]} examinerOrders Examiner orders - API response
 * @returns {ExaminerOrderList[]} formatted examiner order list
 */
const getExaminerOrderList = (examinerOrders: ExaminerOrder[]): ExaminerOrderList[] => {
    const examinerListWithOrders = examinerOrders.reduce(
        (acc: ExaminerOrderList[], currentOrder: ExaminerOrder) => {
            const existingMember = acc.find(
                (order) => currentOrder.assignedTo.id === order.id
            );
            if (existingMember) {
                existingMember.orderList.push(currentOrder);
            } else {
                acc.push({
                    id: currentOrder.assignedTo.id,
                    name: currentOrder.assignedTo.memberName,
                    role: currentOrder.assignedTo.role,
                    stateCounties: currentOrder.assignedTo.stateCounties,
                    orderList: [currentOrder]
                });
            }
            return acc;
        },
        []
    );
    return examinerListWithOrders;
};

/**
 * Updates error state in case of multi order update failure
 * @param {MultipleProjectOrderUpdateResponse[]} response
 * @param {ProjectOrder[]} ordersData
 * @returns {string[]}
 */
export const setMultiUpdateErrors = (
    response: MultipleProjectOrderUpdateResponse[],
    ordersData: ProjectOrder[]
): string[] => {
    const errors: string[] = [];
    response.forEach((project) => {
        if (project.message && !project.result) {
            const orderToFind = ordersData.find((order) => order.id === project.projectId);
            const orderName = orderToFind?.projectOrder?.fileNumber ?? project.projectId;
            const errorMessage = orderName + ' : ' + project.message;
            errors.push(errorMessage);
        }
    });
    return errors;
};

/**
 * re-send order to mark order status as Complete
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const resendOrderThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrder.completeOrder(orderId);
            dispatch(updateOrderProjectStatus(result));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Re-send order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default managerOrdersSlice.reducer;
