import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { GetReqConfig, ProjectOrder } from 'types/dataModels';
import { FinalReviewDto } from 'types/dto/finalReviewDto';
import { PulseWarning, RemoveHyperlinkPayload } from 'types/searchReport';

/**
 * Get final review data
 * @function
 * @category API
 * @param {string} orderId - The order ID of the order you want to get the final review data for.
 * @param {GetReqConfig} config
 * @returns {FinalReviewDto}
 */
export const getFinalReviewDataApi = async (
    orderId: string,
    config: GetReqConfig
): Promise<FinalReviewDto> => {
    try {
        const response = await api.get<FinalReviewDto>(
            `/api/examOrder/${orderId}/finalReview`,
            {
                data: {
                    ...config
                }
            }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Get the warnings for a given order
 * @function
 * @category API
 * @param {string} orderId - Order id of the order you want to get the warnings data for
 * @returns {PulseWarning[]}
 */
export const getFinalReviewWarningsApi = async (orderId: string): Promise<PulseWarning[]> => {
    try {
        const response = await api.get<PulseWarning[]>(
            `/api/examOrder/${orderId}/finalReview/warning`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It solves a warning on a final review
 * @function
 * @category API
 * @param {string} orderId - The id of the order
 * @param {string} warningId - the id of the warning to be solved
 * @returns {PulseWarning[]}
 */
export const solveFinalReviewWarningApi = async (
    orderId: string,
    warningId: string
): Promise<PulseWarning[]> => {
    try {
        const response = await api.put<null, PulseWarning[]>(
            `/api/examOrder/${orderId}/finalReview/warning/${warningId}/solve`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Sets the order to include or exclude hyperlinks in the TSR
 * @param orderId - The id of the order
 * @param useImageHyperlinks - Whether or not to include hyperlinks
 * @returns {ProjectOrder}
 */
export const setImageHyperlinksForOrder = async (
    orderId: string,
    useImageHyperlinks: boolean
): Promise<ProjectOrder> => {
    const payload: RemoveHyperlinkPayload = {
        useImageHyperlinks
    };

    try {
        const response = await api.put<RemoveHyperlinkPayload, ProjectOrder>(
            `/api/orderProject/${orderId}/setImagehyperlinks`,
            payload
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};
