import { NewRowEntry } from "types/configManager";

// Function to verify and encode template only for field definition as Template.
export const verifyAndEncodeTemplate = (newConfig: NewRowEntry): NewRowEntry => {
  const updatedResults = newConfig.results?.map((result) => {
    if (result.name === 'Template' && result.value) {
      return { ...result, value: encodeData(result.value) };
    }
    return result;
  });
  return { ...newConfig, results: updatedResults ?? newConfig.results };
};



// Function to verify and decode template only for field definition as Template.
export const verifyAndDecodeTemplate = (newConfig: NewRowEntry): NewRowEntry => {
  const updatedResults = newConfig.results?.map((result) => {
    if (result.name === 'Template' && result.value) {
      return { ...result, value: decodeData(result.value) };
    }
    return result;
  });
  return { ...newConfig, results: updatedResults ?? newConfig.results };
};


// Function to encode data (e.g., Base64)
export const encodeData = (data: string): string | null => {
  try {
    return btoa(data.trim());
  } catch (error) {
    return null;
  }
};

// Function to decode data (e.g., Base64)
export const decodeData = (data: string): string | null => {
  try {
    data = atob(data).trim();
    return data;
  } catch (error) {
    return data;
  }
};