import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { ExaminerReviewState, GetReqConfig, UpdateSectionStatus } from 'types/dataModels';

/**
 * Get review state for all panels at workbench
 * @function
 * @category API
 * @param {string} orderId - Target order id
 * @param {GetReqConfig} config
 * @returns {ExaminerReviewState}
 */
export const getExamOrderReviewState = async (
    orderId: string,
    config: GetReqConfig
): Promise<ExaminerReviewState> => {
    try {
        const response = await api.get<ExaminerReviewState>(
            `/api/examOrder/${orderId}/reviewState`,
            { data: { ...config } }
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update search report section status
 * @function
 * @category API
 * @param {string} orderId
 * @param {UpdateSectionStatus} value
 * @returns {ExaminerReviewState}
 */
export const updateSearchReportSectionReviewStatus = async (
    orderId: string,
    value: UpdateSectionStatus
): Promise<ExaminerReviewState> => {
    try {
        const response = await api.put<UpdateSectionStatus, ExaminerReviewState>(
            `/api/examOrder/${orderId}/reviewState/section/searchReport`,
            value
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update order details section status
 * @function
 * @category API
 * @param {string} orderId
 * @param {UpdateSectionStatus} value
 * @returns {ExaminerReviewState}
 */
export const updateOrderDetailsSectionReviewStatus = async (
    orderId: string,
    value: UpdateSectionStatus
): Promise<ExaminerReviewState> => {
    try {
        const response = await api.put<UpdateSectionStatus, ExaminerReviewState>(
            `/api/examOrder/${orderId}/reviewState/section/orderDetails`,
            value
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update general tab on order details section status
 * @function
 * @category API
 * @param {string} orderId
 * @param {UpdateSectionStatus} value
 * @returns {ExaminerReviewState}
 */
export const updateOrderDetailsGeneralSectionReviewStatus = async (
    orderId: string,
    value: UpdateSectionStatus
): Promise<ExaminerReviewState> => {
    try {
        const response = await api.put<UpdateSectionStatus, ExaminerReviewState>(
            `/api/examOrder/${orderId}/reviewState/section/orderDetails/general`,
            value
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update properties tab on order details section status
 * @function
 * @category API
 * @param {string} orderId
 * @param {UpdateSectionStatus} value
 * @returns {ExaminerReviewState}
 */
export const updateOrderDetailsPropertiesSectionReviewStatus = async (
    orderId: string,
    value: UpdateSectionStatus
): Promise<ExaminerReviewState> => {
    try {
        const response = await api.put<UpdateSectionStatus, ExaminerReviewState>(
            `/api/examOrder/${orderId}/reviewState/section/orderDetails/properties`,
            value
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update parties tab on order details section status
 * @function
 * @category API
 * @param {string} orderId
 * @param {UpdateSectionStatus} value
 * @returns {ExaminerReviewState}
 */
export const updateOrderDetailsPartiesSectionReviewStatus = async (
    orderId: string,
    value: UpdateSectionStatus
): Promise<ExaminerReviewState> => {
    try {
        const response = await api.put<UpdateSectionStatus, ExaminerReviewState>(
            `/api/examOrder/${orderId}/reviewState/section/orderDetails/parties`,
            value
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update taxes section status
 * @function
 * @category API
 * @param {string} orderId
 * @param {UpdateSectionStatus} value
 * @returns {ExaminerReviewState}
 */
export const updateTaxesSectionReviewStatus = async (
    orderId: string,
    value: UpdateSectionStatus
): Promise<ExaminerReviewState> => {
    try {
        const response = await api.put<UpdateSectionStatus, ExaminerReviewState>(
            `/api/examOrder/${orderId}/reviewState/section/taxes`,
            value
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update exceptions section status
 * @function
 * @category API
 * @param {string} orderId
 * @param {UpdateSectionStatus} value
 * @returns {ExaminerReviewState}
 */
export const updateExceptionsSectionReviewStatus = async (
    orderId: string,
    value: UpdateSectionStatus
): Promise<ExaminerReviewState> => {
    try {
        const response = await api.put<UpdateSectionStatus, ExaminerReviewState>(
            `/api/examOrder/${orderId}/reviewState/section/exceptions`,
            value
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};
