import { AuthenticatedTemplate } from '@azure/msal-react';
import InlineLoadingSpinner from 'components/common/InlineLoadingSpinner';
import LoadingModal from 'components/common/LoadingModal';
import MsalInstanceProvider from 'components/common/MsalInstanceProvider';
import { useAxiosLoader } from 'core/api/api';
import { DroppableSections } from 'core/constants/common';
import { fetchActions } from 'core/features/actions/actionsSlice';
import { fetchBusinessSegments } from 'core/features/businessSegments/businessSegmentsSlice';
import { fetchClient } from 'core/features/client/clientSlice';
import { fetchCounty } from 'core/features/county/countySlice';
import {
    fetchExamOrder,
    fetchProjectOrder,
    setCurrentExamOrder
} from 'core/features/currentExamOrder/currentExamOrderSlice';
import { fetchDocumentTypes } from 'core/features/documentTypes/documentTypesSlice';
import { setDragging } from 'core/features/dragDrop/dragDropSlice';
import { fetchExamCodeBookTemplatesData } from 'core/features/examCodeBook/examCodeBookSlice';
import { fetchExamOrderParentSuccessorsDocumentGroupData } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { fetchExamOrderLegalDescriptionData } from 'core/features/examOrderLegalDescription/examOrderLegalDescriptionSlice';
import { getExamOrderSearchPackageGroupDataThunk } from 'core/features/examOrderSearchPackageGroup/examOrderSearchPackageGroupSlice';
import { fetchExamOrderSearchReportData } from 'core/features/examOrderSearchReport/examOrderSearchReportSlice';
import {
    addCodeIntoStatement,
    fetchExamOrderStartersAndPriorsGroupDataThunk
} from 'core/features/examOrderStartersAndPriorsGroup/examOrderStartersAndPriorsGroupSlice';
import { fetchExamOrderTaxesDataThunk } from 'core/features/examOrderTaxes/examOrderTaxesSlice';
import { fetchExamOrderVestingData } from 'core/features/examOrderVesting/examOrderVestingSlice';
import {
    setIsKeyDocsExpanded,
    setIsManualSearchExpanded,
    setIsOrderHistoryExpanded,
    setIsPinnedWarningsExpanded,
    setIsSCCExpanded,
    setIsSearchPackageExpanded,
    setOpenKeyDocsGroup,
    setOpenSearchPkgGroup
} from 'core/features/fullScreenSidebar/fullScreenSidebarSlice';
import { fetchInterestEstateTypes } from 'core/features/interestEstateType/interestEstateTypeSlice';
import {
    ISetKeyDocumentGroupPayloadAction,
    setKeyDocumentsGroup
} from 'core/features/keyDocumentsGrouping/keyDocumentsGroupingSlice';
import { fetchOrderHistory } from 'core/features/orderHistory/orderHistorySlice';
import { fetchProductTypes } from 'core/features/productTypes/productTypesSlice';
import { fetchProfileData } from 'core/features/profile/profileSlice';
import { fetchProjectStatuses } from 'core/features/projectStatus/projectStatusSlice';
import {
    ISetSearchPackageGroupPayloadAction,
    setSearchPackageGroup
} from 'core/features/searchPackageGrouping/searchPackageGroupingSlice';
import { setWarningsPanelPinnedState } from 'core/features/searchWarnings/searchWarningsSlice';
import {
    ISetStartersAndPriorsGroupPayloadAction,
    setStartersAndPriorsGroup
} from 'core/features/startersAndPriorsGrouping/startersAndPriorsGroupingSlice';
import { fetchStates } from 'core/features/states/statesSlice';
import {
    fetchAllowedStatuses,
    setForceEditable,
    setReadOnlyView
} from 'core/features/workbenchStatus/workbenchStatusSlice';
import {
    fetchExamOrderReviewStateThunk,
    resetWorkbenchTabs,
    setIsFinalReviewVisible
} from 'core/features/workbenchTabs/workbenchTabsSlice';
import {
    setIsFullScreenMode,
    setIsOpen,
    setIsPopoutOpen
} from 'core/features/workenchSidebar/workbenchSidebarSlice';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { useSpecialClientAction } from 'core/hooks/useSpecialClientAction';
import { isEmpty } from 'lodash';
import { Suspense, useEffect, useRef } from 'react';
import { BeforeCapture, DragDropContext, DropResult, Position } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import tinymce from 'tinymce';

export interface DragAndDropCommonLayoutProps {
    name?: string;
    forceEditable?: boolean;
    children: React.ReactNode;
}

/**
 * Render popout layout
 * @constructor
 */
const DragAndDropCommonLayout = ({
    children,
    forceEditable
}: DragAndDropCommonLayoutProps) => {
    // window.name = name || 'drag-and-drop-common-layout';
    const params = useParams();
    const dispatch = useAppDispatch();
    const currentOrder = useAppSelector(
        (state) => state.currentExamOrderData.currentExamOrder
    );
    const [loading] = useAxiosLoader(true);
    const { codeTemplates } = useAppSelector((state) => state.examCodeBookData);
    const clientSelectionRef = useRef<Position>({ x: 0, y: 0 });

    useEffect(() => {
        /* if (!window.name.includes('starters-and-priors-popout-window')) {
            // Below dispatch will hide the small sidebar when open this popout window
            dispatch(setIsPopoutOpen(true));
        } */

        function handleWindowUnload() {
            dispatch(setIsPopoutOpen(false));
            // dispatch(setIsPriorPolicyGeneralInformationOpen(false));
        }

        tinymce._setBaseUrl(process.env.PUBLIC_URL + '/tinymce/');

        window.addEventListener('unload', handleWindowUnload);

        return () => {
            window.removeEventListener('unload', handleWindowUnload);
        };
    }, []);

    /**
     * Fetch initial data
     * and set to default after unmount
     */
    useEffect(() => {
        if (params.orderId !== 'empty') {
            dispatch(fetchProfileData());
            dispatch(fetchExamOrder(params.orderId));
            dispatch(fetchProjectOrder(params.orderId));
            dispatch(setForceEditable(forceEditable));
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(params.orderId));
            dispatch(getExamOrderSearchPackageGroupDataThunk(params.orderId));
            dispatch(fetchExamOrderStartersAndPriorsGroupDataThunk(params.orderId));
            dispatch(fetchOrderHistory(params.orderId));
            dispatch(fetchDocumentTypes());
            dispatch(fetchExamCodeBookTemplatesData(params.orderId));
            dispatch(fetchAllowedStatuses(params.orderId));
            dispatch(fetchExamOrderReviewStateThunk(params.orderId));
            dispatch(fetchExamOrderSearchReportData(params.orderId));
            dispatch(fetchExamOrderTaxesDataThunk(params.orderId));
            dispatch(fetchExamOrderLegalDescriptionData(params.orderId));
            dispatch(fetchExamOrderVestingData(params.orderId));
            dispatch(fetchClient());
            dispatch(fetchStates());
            dispatch(fetchCounty());
            dispatch(fetchProductTypes());
            dispatch(fetchProjectStatuses());
            dispatch(fetchBusinessSegments());
            dispatch(fetchActions());
            dispatch(fetchInterestEstateTypes());
        }
        return () => {
            dispatch(setKeyDocumentsGroup({} as ISetKeyDocumentGroupPayloadAction));
            dispatch(setSearchPackageGroup({} as ISetSearchPackageGroupPayloadAction));
            dispatch(setStartersAndPriorsGroup({} as ISetStartersAndPriorsGroupPayloadAction));
            dispatch(resetWorkbenchTabs());
            dispatch(setCurrentExamOrder(null));
            dispatch(setIsFullScreenMode(false));
            dispatch(setIsOpen(false));
            dispatch(setIsPopoutOpen(false));
            dispatch(setIsFinalReviewVisible(false));
            dispatch(setReadOnlyView(false));
            dispatch(setIsPinnedWarningsExpanded(false));
            dispatch(setIsSCCExpanded(false));
            dispatch(setIsOrderHistoryExpanded(false));
            dispatch(setIsManualSearchExpanded(false));
            dispatch(setIsKeyDocsExpanded(false));
            dispatch(setIsSearchPackageExpanded(false));
            dispatch(setOpenKeyDocsGroup([]));
            dispatch(setOpenSearchPkgGroup([]));
            dispatch(setWarningsPanelPinnedState(false));
            // close popout window when examiner leaves current workbench order.
            if (window.pulse.popoutWindow) {
                window.pulse.popoutWindow.close();
            }
        };
    }, []);

    useSpecialClientAction();

    useEffect(() => {
        function handleMouseMove(event: MouseEvent) {
            const current: Position = {
                x: event.clientX,
                y: event.clientY
            };
            clientSelectionRef.current = current;
        }

        window.addEventListener('mousemove', handleMouseMove, { passive: true });

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    /**
     * Show empty view if current order is empty
     */
    if (isEmpty(currentOrder) || params.orderId === 'empty') {
        return <LoadingModal isOpen={loading} cancelHandler={null} />;
    }

    function codeBeforeCapture(before: BeforeCapture) {
        window.dispatchEvent(
            new CustomEvent('onBeforeCapture', {
                detail: { before, clientSelection: clientSelectionRef.current }
            })
        );
    }

    const codeDragStart = () => {
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    };

    // If drag comes from the codebook, lets now check if where we are trying to drop it
    // The 'Section' variable on codebook templates corelates to whether it is an exc/req
    const handleDragStart = () => {
        dispatch(setDragging(true));
    };

    const codeDragEnd = (result: DropResult) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        if (
            (destination.droppableId === source.droppableId &&
                destination.index === source.index) ||
            destination.droppableId === DroppableSections.Codebook
        ) {
            return;
        }
        if (destination.droppableId.includes(DroppableSections.RequirementsStatments)) {
            const templateId = result.draggableId;
            const documentId = destination.droppableId.split('+')[1];
            const targetRequirementId = destination.droppableId.split('+')[2];
            const code = codeTemplates.find((ct) => ct.id === templateId);
            if (source.droppableId === DroppableSections.Codebook) {
                dispatch(
                    addCodeIntoStatement({
                        documentId,
                        targetStatementId: targetRequirementId,
                        code: code.code,
                        section: 'requirements',
                        phraseText: code.body,
                        codeTemplateId: code.id
                    })
                );
            }
        } else if (destination.droppableId.includes(DroppableSections.ExceptionsStatments)) {
            const templateId = result.draggableId;
            const documentId = destination.droppableId.split('+')[1];
            const targetExceptionId = destination.droppableId.split('+')[2];
            const code = codeTemplates.find((ct) => ct.id === templateId);
            if (source.droppableId === DroppableSections.Codebook) {
                dispatch(
                    addCodeIntoStatement({
                        documentId,
                        targetStatementId: targetExceptionId,
                        code: code.code,
                        phraseText: code.body,
                        section: 'exceptions',
                        codeTemplateId: code.id
                    })
                );
            }
        }
    };

    return (
        <MsalInstanceProvider>
            <>
                <AuthenticatedTemplate>
                    <LoadingModal isOpen={loading} cancelHandler={null} />
                    <DragDropContext
                        onBeforeCapture={codeBeforeCapture}
                        onDragStart={handleDragStart}
                        onBeforeDragStart={codeDragStart}
                        onDragEnd={(result) => {
                            codeDragEnd(result);
                            dispatch(setDragging(false));
                        }}>
                        <Suspense fallback={<InlineLoadingSpinner />}>{children}</Suspense>
                    </DragDropContext>
                </AuthenticatedTemplate>
            </>
        </MsalInstanceProvider>
    );
};

export default DragAndDropCommonLayout;
